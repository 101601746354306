<template>
  <div>
    <v-container
      :class="{
        mobile: $vuetify.breakpoint.smAndDown,
        page: $vuetify.breakpoint.mdAndUp
      }"
    >
      <DesktopBackButton v-if="$vuetify.breakpoint.mdAndUp" class="mt-2" />

      <div v-if="loaded">
        <div
          v-if="staff && staff.detuserid"
          :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
          class="d-flex mt-3"
        >
          <div class="flex-shrink-1 order-md-2 ml-1 mb-3">
            <StaffDetailsCard
              :location-id="locationId"
              :staff="staff"
              :staff-id="staffId"
            />
          </div>

          <div
            v-if="fnActionByName(uIActions.LIST_APPLICATIONS)"
            :class="{ 'mt-2': $vuetify.breakpoint.smAndDown }"
            class="flex-grow-1 order-md-1 mt-3"
          >
            <v-card class="msa-list">
              <v-row class="data-table--header pl-3 pr-3">
                <v-col class="pl-0 pr-0">
                  <div class="d-flex align-center pa-6">
                    <div class="mr-6">
                      <AppIcon :app-name="application.icon" />
                    </div>
                    <div>
                      <h3>{{ application.applicationDesc }}</h3>
                    </div>
                    <v-spacer />
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-3">
                      <v-btn
                        v-if="fnActionByName(uIActions.DEPROVISION_APPLICATION)"
                        id="app-tasks--remove-all-access-button"
                        :disabled="!hasPermissions"
                        class="white--text pl-5 pr-5"
                        color="#ce0037"
                        height="52px"
                        @click="removeAllAccess"
                      >
                        <v-icon class="mr-3">
                          mdi-delete-outline
                        </v-icon>
                        Remove app
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div v-if="hasTasks">
                <v-list two-line>
                  <div
                    v-for="task in taskList"
                    :id="`app-tasks--row-${task.taskId}`"
                    :key="task.taskId"
                    class="app-tasks--row"
                  >
                    <v-list-item
                      :aria-label="
                        task.taskName +
                          ' ' +
                          (task.hasAccess ? 'checked' : 'unchecked')
                      "
                      :disabled="task.isPending"
                      class="pl-7"
                      tabindex="0"
                      @click="togglePermission(task)"
                      @keydown="checkEnter($event, task)"
                    >
                      <v-list-item-content style="cursor: pointer">
                        <v-row>
                          <v-col class="col-xs-12 col-md-8">
                            <v-list-item-title
                              :id="`app-tasks--row-task-name-${task.taskId}`"
                              class="font-weight-bold mb-1"
                            >
                              <span>{{ task.taskName }}</span>
                            </v-list-item-title>
                            <div class="app-tasks--description">
                              {{ task.taskDesc }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                      <v-list-item-action-text>
                        <AsyncCheckbox
                          :id="`app-tasks--row-${task.taskId}-checkbox`"
                          :aria-label="task.taskName"
                          :context="provisionedTasks"
                          :disabled="
                            !fnActionByName(uIActions.PROVISION_APPLICATION) &&
                              !fnActionByName(uIActions.DEPROVISION_APPLICATION)
                          "
                          :item="task"
                          key-prop="taskId"
                        />
                      </v-list-item-action-text>
                    </v-list-item>
                  </div>
                </v-list>
                <v-divider v-if="$vuetify.breakpoint.smAndDown" />
              </div>
              <div v-if="!hasTasks">
                <div class="pl-4 pt-4 pb-2">
                  <p>{{ application.applicationDesc }} has no tasks.</p>
                </div>
              </div>
            </v-card>
          </div>
          <div
            v-if="$vuetify.breakpoint.smAndDown"
            class="pa-3 mobile-remove-button-container"
          >
            <v-btn
              v-if="
                !hasBirthright &&
                  fnActionByName(uIActions.DEPROVISION_APPLICATION)
              "
              id="app-tasks--remove-all-access-button"
              :disabled="!hasPermissions"
              class="white--text pl-5 pr-5"
              color="#ce0037"
              height="52px"
              @click="removeAllAccess"
            >
              <v-icon class="pr-3">
                mdi-delete-outline
              </v-icon>
              Remove app
            </v-btn>
          </div>
          <Dialog
            id="app-tasks--remove-all-access-confirmation-button"
            ref="removeAccessDialog"
            :actions="dialogActions"
            :callback="removeAccessConfirm"
            :message="
              `Are you sure you want to remove all ${application.applicationDesc} access from ${fullName}?`
            "
            title="Reset access"
            type="warning"
          />
        </div>
      </div>
      <Dialog
        ref="disablePermissionForStaffUser"
        :message="clientErrors.isStaff"
        actions="Ok"
        title="Not authorised"
        type="error"
      />
      <Dialog
        ref="disablePermissionForGuestUser"
        :message="clientErrors.isGuest"
        actions="Ok"
        title="Not authorised"
        type="error"
      />
      <Dialog
        ref="netRegIdNotPresent"
        :message="clientErrors.shortNameMissing"
        actions="Ok"
        title="A system error has occurred"
        type="error"
      />
    </v-container>
  </div>
</template>

<script>
import { AppIcon } from "@nswdoe/doe-ui-core";
import Dialog from "@/components/Dialog";
import DesktopBackButton from "@/components/DesktopBackButton";
import StaffDetailsCard from "@/components/StaffDetailsCard";
import AsyncCheckbox from "@/components/AsyncCheckbox";
import { mapGetters } from "vuex";
import {
  DIALOG_ACTIONS,
  MSA_CLIENT_ERROR,
  UI_ACTIONS
} from "@/utils/constants";

export default {
  components: {
    DesktopBackButton,
    StaffDetailsCard,
    AppIcon,
    Dialog,
    AsyncCheckbox
  },
  props: {
    staffId: {
      type: String,
      default: null
    },
    applicationCode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loaded: false,
      isExternalStaff: this.$store.getters.isExternalStaff(
        this.$store.getters.selectedSchoolId,
        this.staffId
      ),
      disablePermissionForStaffUser: false,
      disablePermissionForGuestUser: false,
      netRegIdNotPresent: false,
      showResetAccess: false,
      uIActions: UI_ACTIONS,
      dialogActions: DIALOG_ACTIONS,
      clientErrors: MSA_CLIENT_ERROR
    };
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
      appsChanged: "reloadApplicationsRequired",
      fnActionByName: "getActionByName"
    }),
    staff() {
      return (
        this.$store.getters.staff(this.locationId, this.staffId) ||
        this.$store.getters.addStaff
      );
    },
    fullName() {
      return this.staff.firstname + " " + this.staff.lastname;
    },
    application() {
      return this.$store.getters.application(
        this.locationId,
        this.staffId,
        this.applicationCode
      );
    },
    taskList() {
      return this.$store.getters.taskList(this.applicationCode);
    },
    hasPermissions() {
      return this.taskList.some(t => t.hasAccess);
    },
    provisionedTasks() {
      return this.taskList
        ? this.taskList.filter(t => t.hasAccess).map(t => t.taskId)
        : [];
    },
    hasTasks() {
      return this.taskList && this.taskList.length > 0;
    },
    locationName() {
      const location = this.$store.getters.locations.find(
        l => l.id === this.locationId
      );
      return location ? location.name : "";
    },
    locationType() {
      //console.log("Before determining the location type for: locationId-"+this.locationId);
      const location = this.$store.getters.locations.find(
      l => l.id === this.locationId
      );
      //console.log("After determining the location type: locationType-"+location.locType);
      return location ? location.locType : "";
    },
    hasBirthright() {
      return this.taskList.some(t => t.birthright);
    }
  },
  created: async function() {
    if (
      !this.application ||
      !this.application.applicationCode ||
      this.appsChanged
    ) {
      //console.log("Before calling fetchAppsForStaffAtLocation from created func: locType-" + this.locationType);
      await this.$store.dispatch("fetchAppsForStaffAtLocation", {
        locationId: this.locationId,
        locType: this.locationType,
        staffId: this.staffId
      });
      this.$store.commit("setReloadApplicationsRequired", false);
    }

    this.loaded = true;
    window.scroll(0, 0);

    if (this.application.applicationId === 32 && !this.isExternalStaff) {
      // disable "portal resources" for staff users
      this.disablePermissionForStaffUser = true;
    }
    if (this.application.applicationId === 3 && this.isExternalStaff) {
      // disable "Schools OASIS Archive Application" for external
      this.disablePermissionForGuestUser = true;
    }
  },
  methods: {
    removeAllAccess() {
      if (this.disablePermissionForStaffUser) {
        this.$refs.disablePermissionForStaffUser.open();
        return;
      }
      if (this.disablePermissionForGuestUser) {
        this.$refs.disablePermissionForGuestUser.open();
        return;
      }
      this.$refs.removeAccessDialog.open();
    },
    removeAccessConfirm(result) {
      if (result !== "Yes") return;
      if (this.isExternalStaff) {
        this.$store.commit("setReloadStaffListRequired");
      }

      //console.log("Before calling deprovisionApp from removeAccessConfirm: locType-" + this.locationType);
      const params = {
        locationId: parseInt(this.locationId),
        locType: this.locationType,
        locationName: this.locationName,
        detUserId: this.staff.detuserid,
        staffId: this.staff.netRegId,
        applicationId: this.application.applicationId,
        applicationCode: this.application.applicationCode,
        revokeAll: true
      };
      if (this.staff.netRegId) {
        this.netRegIdNotPresent = false;
        return this.$store.dispatch("deprovisionApp", params).then(() => {
          this.$router.go(-1);
        });
      }
      this.netRegIdNotPresent = true;
      this.$refs.netRegIdNotPresent.open();
    },
    togglePermission(task) {
      if (!task) {
        return;
      }
      if (
        this.fnActionByName(UI_ACTIONS.PROVISION_APPLICATION) ||
        this.fnActionByName(UI_ACTIONS.DEPROVISION_APPLICATION)
      ) {
        const taskId = task.taskId;
        if (taskId != null) {
          if (this.disablePermissionForStaffUser) {
            this.$refs.disablePermissionForStaffUser.open();
            return;
          }
          if (this.disablePermissionForGuestUser) {
            this.$refs.disablePermissionForGuestUser.open();
            return;
          }
          const wasProvisioned = this.provisionedTasks.some(t => t === taskId);

          if (this.isExternalStaff) {
            // this user may be added/removed from the external staff list
            this.$store.commit("setReloadStaffListRequired");
          }
          //console.log("Before preparing params for provisionTask: locType-"+this.locationType);
          const params = {
            locationId: parseInt(this.locationId),
            locType: this.locationType,
            locationName: this.locationName,
            detUserId: this.staff.detuserid,
            staffId: this.staff.netRegId,
            applicationId: this.application.applicationId,
            applicationCode: this.application.applicationCode,
            taskId: taskId,
            provisioned: !wasProvisioned,
            revokeAll: false
          };

          if (this.staff.netRegId) {
            this.netRegIdNotPresent = false;
            this.$store.dispatch("provisionTask", params);
            this.$store.commit("setReloadStaffListRequired");
          } else {
            this.netRegIdNotPresent = true;
            this.$refs.netRegIdNotPresent.open();
          }
        }
      }
    },
    checkEnter(event, task) {
      if (event.keyCode === 13) {
        this.togglePermission(task);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-font {
  font-size: 29px !important;
}

.essential svg {
  width: 40px;
  height: 40px;
  vertical-align: 1px;
  border-radius: 0.2rem;

  &::before {
    margin: 0 !important;
  }
}

.v-list-item__avatar {
  min-width: 54px;
  margin-right: 8px !important;
}

button.remove-access-button[disabled] {
  span {
    color: rgba(0, 0, 0, 0.54) !important;
    cursor: not-allowed;
  }
}

#msa-app div.v-list span.v-list-item__action-text {
  div.v-input,
  ::v-deep div.v-messages {
    background-color: transparent;
  }
}

::v-deep .v-list-item__title,
::v-deep .v-list-item__subtitle {
  overflow: visible;
  white-space: pre-wrap;
}

::v-deep .app-tasks--description {
  line-height: 1.5rem;
  color: #4c4f55;
}

::v-deep .v-list--two-line .v-list-item {
  min-height: 115px;
  overflow: visible;
  white-space: normal;
}

::v-deep .app-tasks--row:not(:last-of-type) {
  border-bottom: 1px solid #e5e5e5;
}

::v-deep .v-list-item__content {
  overflow: visible;
}

.mobile-remove-button-container {
  background-color: $ads-white;
}

.theme--dark {
  .data-table--header {
    .col {
      background-color: #263238;
    }
  }
}

.data-table--header {
  .col {
    background-color: #cfd8dc;
  }
}
</style>
