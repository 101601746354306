<template>
  <div>
    <div class="mr-2">
      <v-progress-circular
        v-if="item.isPending"
        mr-3
        size="23"
        width="1.5"
        color="primary"
        indeterminate
      />
    </div>
    <v-checkbox
      v-if="!item.isPending"
      v-model="context"
      :class="getCheckboxColour(item)"
      :value="item[keyProp]"
      :ref="item.code + '_checkbox'"
      @click="$event.preventDefault()"
      v-bind="{...$attrs}"
    />
  </div>
</template>

<script>
  export default {
    name: "AsyncCheckbox",
    props: {
      item: {
        type: Object,
        default: () => {}
      },
      context: {
        type: Array,
        default: () => []
      },
      keyProp: {
        type: String,
        default: 'id'
      }
    },
    methods: {
      getCheckboxColour(item) {
        if (item.hasError) {
          return "error--checkbox";
        }
        if (item.isPending != null) {
          return "success--checkbox";
        }
      }
    }
  }
</script>

<style scoped>
  /* provision/deprovision checkboxes */
  /* ticked is always green */
  ::v-deep
    div.theme--light
    input[aria-checked="true"][type="checkbox"]:not(:disabled)
    ~ i.v-icon {
    color: green !important;
  }
  /* modified successfully is always green */
  ::v-deep div.theme--light.success--checkbox input[type="checkbox"] ~ i.v-icon {
    color: green !important;
  }
  /* error is red */
  ::v-deep div.theme--light.error--checkbox input[type="checkbox"] ~ i.v-icon {
    color: red !important;
  }
</style>