var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{class:{
      mobile: _vm.$vuetify.breakpoint.smAndDown,
      page: _vm.$vuetify.breakpoint.mdAndUp
    }},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('DesktopBackButton',{staticClass:"mt-2"}):_vm._e(),(_vm.loaded)?_c('div',[(_vm.staff && _vm.staff.detuserid)?_c('div',{staticClass:"d-flex mt-3",class:{ 'flex-column': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"flex-shrink-1 order-md-2 ml-1 mb-3"},[_c('StaffDetailsCard',{attrs:{"location-id":_vm.locationId,"staff":_vm.staff,"staff-id":_vm.staffId}})],1),(_vm.fnActionByName(_vm.uIActions.LIST_APPLICATIONS))?_c('div',{staticClass:"flex-grow-1 order-md-1 mt-3",class:{ 'mt-2': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-card',{staticClass:"msa-list"},[_c('v-row',{staticClass:"data-table--header pl-3 pr-3"},[_c('v-col',{staticClass:"pl-0 pr-0"},[_c('div',{staticClass:"d-flex align-center pa-6"},[_c('div',{staticClass:"mr-6"},[_c('AppIcon',{attrs:{"app-name":_vm.application.icon}})],1),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.application.applicationDesc))])]),_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"ml-3"},[(_vm.fnActionByName(_vm.uIActions.DEPROVISION_APPLICATION))?_c('v-btn',{staticClass:"white--text pl-5 pr-5",attrs:{"id":"app-tasks--remove-all-access-button","disabled":!_vm.hasPermissions,"color":"#ce0037","height":"52px"},on:{"click":_vm.removeAllAccess}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v(" mdi-delete-outline ")]),_vm._v(" Remove app ")],1):_vm._e()],1):_vm._e()],1)])],1),(_vm.hasTasks)?_c('div',[_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.taskList),function(task){return _c('div',{key:task.taskId,staticClass:"app-tasks--row",attrs:{"id":("app-tasks--row-" + (task.taskId))}},[_c('v-list-item',{staticClass:"pl-7",attrs:{"aria-label":task.taskName +
                        ' ' +
                        (task.hasAccess ? 'checked' : 'unchecked'),"disabled":task.isPending,"tabindex":"0"},on:{"click":function($event){return _vm.togglePermission(task)},"keydown":function($event){return _vm.checkEnter($event, task)}}},[_c('v-list-item-content',{staticStyle:{"cursor":"pointer"}},[_c('v-row',[_c('v-col',{staticClass:"col-xs-12 col-md-8"},[_c('v-list-item-title',{staticClass:"font-weight-bold mb-1",attrs:{"id":("app-tasks--row-task-name-" + (task.taskId))}},[_c('span',[_vm._v(_vm._s(task.taskName))])]),_c('div',{staticClass:"app-tasks--description"},[_vm._v(" "+_vm._s(task.taskDesc)+" ")])],1)],1)],1),_c('v-list-item-action-text',[_c('AsyncCheckbox',{attrs:{"id":("app-tasks--row-" + (task.taskId) + "-checkbox"),"aria-label":task.taskName,"context":_vm.provisionedTasks,"disabled":!_vm.fnActionByName(_vm.uIActions.PROVISION_APPLICATION) &&
                            !_vm.fnActionByName(_vm.uIActions.DEPROVISION_APPLICATION),"item":task,"key-prop":"taskId"}})],1)],1)],1)}),0),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-divider'):_vm._e()],1):_vm._e(),(!_vm.hasTasks)?_c('div',[_c('div',{staticClass:"pl-4 pt-4 pb-2"},[_c('p',[_vm._v(_vm._s(_vm.application.applicationDesc)+" has no tasks.")])])]):_vm._e()],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"pa-3 mobile-remove-button-container"},[(
              !_vm.hasBirthright &&
                _vm.fnActionByName(_vm.uIActions.DEPROVISION_APPLICATION)
            )?_c('v-btn',{staticClass:"white--text pl-5 pr-5",attrs:{"id":"app-tasks--remove-all-access-button","disabled":!_vm.hasPermissions,"color":"#ce0037","height":"52px"},on:{"click":_vm.removeAllAccess}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v(" mdi-delete-outline ")]),_vm._v(" Remove app ")],1):_vm._e()],1):_vm._e(),_c('Dialog',{ref:"removeAccessDialog",attrs:{"id":"app-tasks--remove-all-access-confirmation-button","actions":_vm.dialogActions,"callback":_vm.removeAccessConfirm,"message":("Are you sure you want to remove all " + (_vm.application.applicationDesc) + " access from " + _vm.fullName + "?"),"title":"Reset access","type":"warning"}})],1):_vm._e()]):_vm._e(),_c('Dialog',{ref:"disablePermissionForStaffUser",attrs:{"message":_vm.clientErrors.isStaff,"actions":"Ok","title":"Not authorised","type":"error"}}),_c('Dialog',{ref:"disablePermissionForGuestUser",attrs:{"message":_vm.clientErrors.isGuest,"actions":"Ok","title":"Not authorised","type":"error"}}),_c('Dialog',{ref:"netRegIdNotPresent",attrs:{"message":_vm.clientErrors.shortNameMissing,"actions":"Ok","title":"A system error has occurred","type":"error"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }